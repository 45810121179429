/*
Toc：
    applybutton.css：友链申请
    aside_toc.css：透明目录
    asidefloat.css：左侧悬浮栏
    bg.css：一图流
    color.css：全局颜色
    cursor.css：鼠标指针
    custom.css：全局微调 不记录教程
    dark span.css：刮刮乐
    double row.css：双栏布局
    memos.css：首页轮播BB
    nav_menu.css：导航栏美化
    rightmenu.css：右击美化
    social_card.css：社交卡片美化
    twikoo.css：评论美化
    universe.css：星空背景
 */

/************ applybutton.css start ************/
.addBtn button:hover {
    background: #da6c7d;
    color: white;
}

[data-theme=dark] .addBtn button:hover {
    background: #96a7be;
}

.addBtn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.addBtn i {
    font-size: 1.3rem;
    margin-right: 10px;
}

.addBtn button {
    transtion: .2s;
    display: flex;
    margin: 5px auto;
    color: var(--global-bg);
    padding: 15px;
    border-radius: 12px;
    background: var(--zhsher-main);
    align-items: center;
}

/************ applybutton.css end ************/

/************ aside_toc.css start ************/
/* PC端下目录透明 */
@media screen and (min-width: 900px) {
    #aside-content .card-widget#card-toc {
        background-color: transparent !important;
        box-shadow: none !important;
        border: none;
    }

    #aside-content #card-toc .item-headline {
        opacity: 0;
    }
}

/* 去掉主题原先的杠 */
#aside-content #card-toc .toc-content > * > .toc-item > .toc-child {
    border-left: none !important;
}

/* 当前小节前样式及小杠 */
#aside-content #card-toc .toc-content .toc-link.active {
    display: flex;
    transform: scale(1.2);
    background: none;
    color: var(--zhsher-lighttext);
    font-weight: bold;
}

#aside-content #card-toc .toc-content .toc-link.active:before {
    content: " " !important;
    margin-top: 0.8rem !important;
    margin-right: 0.5rem;
    width: 0.8rem !important;
    height: 0.2rem !important;
    border-radius: 0.1rem !important;
    background: var(--zhsher-lighttext);
}

/* 隐藏滚动条 */
#aside-content #card-toc .toc-content::-webkit-scrollbar-thumb, #aside-content #card-toc .toc-content::-webkit-scrollbar-corner {
    background-color: transparent;
}

/************ aside_toc.css end ************/

/************ asidefloat.css start ************/
.contact-info {
    position: fixed;
    top: 22%;
    z-index: 200;
    left: -51px;
    transition: 0.4s;
}

.contact-info:hover {
    left: 0px;
}

.contact-info .option {
    cursor: pointer;
    position: relative;
}

.contact-info .option i {
    display: block;
    width: 50px;
    text-align: center;
    height: 60px;
    line-height: 60px;
    background: rgb(255, 255, 255);
    color: #b9b9b9;
    font-size: 20px;
    transition: 0.4s;
    border-radius: 0 10px 10px 0;
}

.contact-info .option:hover i {
    color: #3498db;
}

.contact-info .text {
    border-radius: 15px;
    position: absolute;
    height: 68px;
    width: 200px;
    background: rgba(255, 255, 255, .85);
    top: 0;
    z-index: -1;
    left: -136px;
    color: rgb(0, 0, 0);
    line-height: 60px;
    text-align: center;
    transition: 0.4s;
}

.contact-info .aplayertext {
    transition: none !important;
}

.contact-info .aplayer {
    position: absolute !important;
}

.contact-info .aplayer.aplayer-fixed .aplayer-body {
    left: -60px;
    position: absolute !important;
    width: 370px !important;
    transition: none !important;
    border-radius: 10px !important;
}

.contact-info .aplayer.aplayer-fixed {
    left: -300px !important;
}

.contact-info .option:hover .aplayer.aplayer-fixed .aplayer-body, .contact-info .option:hover .aplayer.aplayer-fixed {
    left: 0px !important;
}

.contact-info .option:hover .text {
    left: 60px;
}

.contact-info .blok {
    position: absolute;
    height: 60px;
    width: 100px;
    top: 0;
    z-index: -1;
    left: 0px;
}

.contact-info .bloktop {
    position: absolute;
    height: 75px;
    width: 100px;
    bottom: 0px;
    z-index: -1;
    left: 0px;
}

.contact-info .blokbottom {
    position: absolute;
    height: 75px;
    width: 100px;
    top: 0;
    z-index: -1;
    left: 0px;
}

.contact-info .option:hover .blok, .contact-info .option:hover .bloktop, .contact-info .option:hover .blokbottom {
    left: 0px;
}

.contact-info .option:hover .aplayertext {
    left: 60px !important;
    width: 370px !important;
    background: transparent !important;
}

.contact-info .aplayer.aplayer-fixed .aplayer-miniswitcher {
    border-radius: 0 10px 10px 0 !important;
}

.contact-info .aplayer.aplayer-fixed .aplayer-body {
    transition: 0.28s !important;
    border-radius: 10px !important;
    height: 60px;
}

.contact-info .aplayer .aplayer-pic {
    height: 60px !important;
}

.contact-info .aplayer .aplayer-info {
    height: 60px !important;
}

.aplayer .aplayer-list.aplayer-list-hide {
    margin-bottom: 59px !important;
}

.contact-info .aplayer.aplayer-fixed .aplayer-list {
    margin-bottom: 62px !important;
    max-height: 240px !important;
}

.contact-info .aplayer.aplayer-fixed .aplayer-info {
    transition: none !important;
}

.aplayer.aplayer-narrow .aplayer-body, .aplayer.aplayer-narrow .aplayer-pic {
    border-radius: 10px 0 0 10px !important;
}

.aplayer.aplayer-fixed {
    border-radius: 10px !important;
}

.aplayer .aplayer-pic {
    border-radius: 0 0 0 10px !important;
}

.aplayer.aplayer-fixed .aplayer-list {
    border-radius: 10px 10px 0 0 !important;
}

[data-theme=dark]
.contact-info .option i {
    background: rgb(22, 22, 22);
    color: #b9b9b9;
}

[data-theme=dark]
.contact-info .text {
    background: rgba(23, 23, 23, 0.85);
    color: rgba(255, 255, 255, 0.92);
}

@media screen and (max-width: 1300px) {
    .contact-info {
        display: none !important;
    }
}

.aplayer {
    opacity: .93 !important;
}

[data-theme=dark]
.aplayer {
    background: rgb(22, 22, 22) !important;
    color: rgb(255, 255, 255);
}

[data-theme=dark]
.aplayer.aplayer-fixed .aplayer-body {
    background: rgb(22, 22, 22) !important;
    color: rgb(255, 255, 255);
}

[data-theme=dark]
.aplayer .aplayer-list ol li:hover {
    background: #3b3b3b;
}

[data-theme=dark]
.aplayer .aplayer-list ol li.aplayer-list-light {
    background: #686868;
}

[data-theme=dark]
.aplayer .aplayer-info .aplayer-controller .aplayer-time {
    color: #d4d4d4;
}

[data-theme=dark]
.aplayer .aplayer-list ol li .aplayer-list-index {
    color: #d4d4d4;
}

[data-theme=dark]
.aplayer .aplayer-list ol li .aplayer-list-author {
    color: #d4d4d4;
}

[data-theme=dark]
.aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon path {
    fill: #d4d4d4;
}

.contact-info .text .strip {
    border-radius: 5px;
    position: absolute;
    height: 36px;
    width: 5px;
    background: rgba(20, 163, 230, 0.8);
    top: 12px;
    z-index: -1;
    right: 4px;
    transition: 0.4s;
}

.contact-info .aplayertext .strip {
    transition: none !important;
}

.contact-info .option:hover .aplayertext .strip {
    background: transparent;
}

[data-theme=dark]
.contact-info .aplayer .aplayer-miniswitcher {
    background: rgba(23, 23, 23, 0.85);
}

.contact-info .aplayer .aplayer-miniswitcher .aplayer-icon path {
    fill: #3498db;
}

[data-theme=dark]
.contact-info .option:hover i {
    color: #3498db;
}

.contact-info .option .text .post-reward .tip-button__text {
    margin: 25px !important;
}

.contact-info .option .text .post-reward .reward-main .reward-all {
    border-radius: 12px !important;
}

.contact-info .option .text .post-reward .reward-button .reward-main .reward-all {
    z-index: 999 !important;
}

[data-theme="dark"]
.contact-info .option .text .post-reward .tip-button {
    border: solid 2px rgba(236, 233, 233, 0.8);
    background: #043749d0;
}


/* 基于chuckle的微调，可能与你的博客不适配，根据注释微调 */
/* 第一个按钮的滚动通知 */
/* 通知栏大小 文字行高调整 */
.contact-info .option:first-child:hover .text {
    height: 100px;
    width: 300px;
    line-height: 20px;
    overflow: hidden;
}

/* 小图标调整 */
.contact-info .option:first-child:hover .text .strip {
    top: 33px;
}

.contact-info .option .text .aside_notice {
    word-wrap: break-word;
    padding: 20px;
    /*color: transparent; !*偷个懒不做位移直接透明*!*/
    display: none;
}

/* 文字滚动动画 */
.contact-info .option:first-child:hover .text .aside_notice {
    color: #0A84FF;
    display: block;
    animation: notice 5s linear 0s infinite normal forwards;
}

/* 鼠标悬停时停止滚动 */
.contact-info .option .text .aside_notice:hover {
    animation-play-state: paused !important;
    cursor: pointer;
}

/* 文字滚动动画 */
@keyframes notice {
    0% {
        transform: translateY(90px);
    }
    100% {
        transform: translateY(-100%);
    }
}

/* 打赏按钮大小与布局 */
.contact-info .option .text .post-reward .reward-button {
    display: inline-flex;
    width: 90%;
    position: relative;
    left: -9.7px;
    border-radius: 15px;
}

.contact-info .option .text .post-reward {
    margin-top: 0px;
}

/* 二维码横向排列 */
.contact-info .option .text .post-reward .reward-main .reward-all {
    display: inline-flex;
}

/* 二维码靠边对齐 */
.contact-info .option .text .post-reward .reward-main {
    left: -60px;
    line-height: 30px;
}

/* 倒三角位置 */
.contact-info .option .text .post-reward .reward-main .reward-all:after {
    left: 110px;
}

/* 充电矢量图动画 */
.contact-info .option .text .post-reward .reward-button i {
    background: yellow;
    border-radius: 10px;
    animation: Breath 1.2s linear 1s infinite;
    color: var(--btn-bg) !important;
    position: relative;
    left: 46px;
}

@keyframes Breath {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}


/* 打赏按钮hover动画 */
.contact-info .option .text .post-reward:hover .reward-button {
    background: linear-gradient(to left, yellow 50%, var(--btn-bg) 50%);
    background-size: 200%;
    background-position-x: -100%;
    transition: .5s ease-out;
}

/************ asidefloat.css end ************/

/************ bg.css start ************/
/*https://blog.csdn.net/qq_43740362/article/details/113790851*/
/* 一图流 */
/* 页脚透明 */
#footer {
    background: transparent !important;
}

/* 页脚黑色透明玻璃效果移除 */
#footer::before {
    background: transparent !important;
}

/* 头图透明 */
#page-header {
    background: transparent !important;
}

/* 头图遮罩层透明 */
#page-header::before {
    background: transparent !important;
}

/*top-img黑色透明玻璃效果移除，不建议加，除非你执着于完全一图流或者背景图对比色明显 */
#page-header.post-bg:before {
    background-color: transparent !important;
}

/*夜间模式伪类遮罩层透明*/
[data-theme="dark"]
#footer::before {
    background: transparent !important;
}

[data-theme="dark"]
#page-header::before {
    background: transparent !important;
}

/************ bg.css end ************/

/************ color.css start ************/
/* 全局颜色 */
:root {
    --zhsher-white: #fff;
    --zhsher-white-op: rgba(255, 255, 255, 0.2);
    --zhsher-black: #000;
    --zhsher-black-op: rgba(0, 0, 0, 0.2);
    --zhsher-none: #00000000;
    --zhsher-gray: #999999;
    --zhsher-border-radius: 8px;
    --style-border-always: 1px solid var(--zhsher-card-border);
    --zhsher-gray-op: #9999992b;
    --zhsher-vip: #e5a80d;
    --zhsher-main: var(--zhsher-theme);
    --zhsher-main-op: var(--zhsher-theme-op);
    --zhsher-main-op-deep: var(--zhsher-theme-op-deep);
    --zhsher-main-none: var(--zhsher-theme-none);
    --zhsher-shadow-theme: 0 8px 12px -3px var(--zhsher-theme-op);
    --zhsher-shadow-blackdeep: 0 2px 16px -3px rgba(0, 0, 0, .15);
    --zhsher-shadow-main: 0 8px 12px -3px var(--zhsher-main-op);
    --zhsher-shadow-blue: 0 8px 12px -3px rgba(40, 109, 234, .20);
    --zhsher-shadow-white: 0 8px 12px -3px rgba(255, 255, 255, .20);
    --zhsher-shadow-black: 0 0 12px 4px rgba(0, 0, 0, .05);
    --zhsher-shadow-yellow: 0px 38px 77px -26px rgba(255, 201, 62, .12);
    --zhsher-shadow-red: 0 8px 12px -3px #ee7d7936;
    --zhsher-shadow-green: 0 8px 12px -3px #87ee7936;
    --zhsher-logo-color: linear-gradient(215deg, #4584ff 0%, #cf0db9 100%);
    --zhsher-snackbar-time: 2s;
}

[data-theme=light] {
    --zhsher-theme: #425AEF;
    --zhsher-theme-op: #4259ef23;
    --zhsher-theme-op-deep: #4259efdd;
    --zhsher-theme-none: #4259ef01;
    --zhsher-blue: #425AEF;
    --zhsher-red: #f04a63;
    --zhsher-pink: #FF7C7C;
    --zhsher-green: #57bd6a;
    --zhsher-yellow: #c28b00;
    --zhsher-yellow-op: #d99c001a;
    --zhsher-orange: #e38100;
    --zhsher-fontcolor: #363636;
    --zhsher-background: #f7f9fe;
    --zhsher-reverse: #000;
    --zhsher-maskbg: rgba(255, 255, 255, 0.6);
    --zhsher-maskbgdeep: rgba(255, 255, 255, 0.85);
    --zhsher-hovertext: var(--zhsher-main);
    --zhsher-ahoverbg: #F7F7FA;
    --zhsher-lighttext: var(--zhsher-main);
    --zhsher-secondtext: rgba(60, 60, 67, 0.6);
    --zhsher-scrollbar: rgba(59, 121, 253, 0.9);
    --zhsher-card-btn-bg: #edf0f7;
    --zhsher-post-blockquote-bg: #fafcff;
    --zhsher-post-tabs-bg: #f2f5f8;
    --zhsher-secondbg: #f1f3f8;
    --zhsher-shadow-nav: 0 5px 12px -5px rgba(102, 68, 68, 0.05);
    --zhsher-card-bg: #fff;
    --zhsher-card-bg-op: var(--zhsher-black-op);
    --zhsher-card-bg-none: rgba(255, 255, 255, 0);
    --zhsher-shadow-lightblack: 0 5px 12px -5px rgba(102, 68, 68, 0.00);
    --zhsher-shadow-light2black: 0 5px 12px -5px rgba(102, 68, 68, 0.00);
    --zhsher-card-border: #e3e8f7;
    --zhsher-shadow-border: 0 8px 16px -4px #2c2d300c;
    --style-border: 1px solid var(--zhsher-card-border);
    --style-border-hover: 1px solid var(--zhsher-main);
    --style-border-hover-always: 1px solid var(--zhsher-main);
    --style-border-dashed: 1px dashed var(--zhsher-theme-op);
    --style-border-forever: 2px solid var(--zhsher-main);
}

[data-theme=dark] {
    --zhsher-theme: #f2b94b;
    --zhsher-theme-op: #f2b94b23;
    --zhsher-theme-op-deep: #f2b94bdd;
    --zhsher-theme-none: #f2b94b00;
    --zhsher-blue: #0084FF;
    --zhsher-red: #FF3842;
    --zhsher-pink: #d44040;
    --zhsher-green: #3e9f50;
    --zhsher-yellow: #ffc93e;
    --zhsher-yellow-op: #ffc93e30;
    --zhsher-orange: #ff953e;
    --zhsher-fontcolor: #F7F7FA;
    --zhsher-background: #18171d;
    --zhsher-reverse: #fff;
    --zhsher-maskbg: rgba(0, 0, 0, 0.6);
    --zhsher-maskbgdeep: rgba(0, 0, 0, 0.85);
    --zhsher-hovertext: #0A84FF;
    --zhsher-ahoverbg: #fff;
    --zhsher-lighttext: var(--zhsher-theme);
    --zhsher-secondtext: #a1a2b8;
    --zhsher-scrollbar: rgba(200, 200, 223, 0.4);
    --zhsher-card-btn-bg: #30343f;
    --zhsher-post-blockquote-bg: #000;
    --zhsher-post-tabs-bg: #121212;
    --zhsher-secondbg: #30343f;
    --zhsher-shadow-nav: 0 5px 20px 0px rgba(28, 28, 28, 0.4);
    --zhsher-card-bg: #1d1e22;
    --zhsher-card-bg-op: var(--zhsher-white-op);
    --zhsher-card-bg-none: #1d1b2600;
    --zhsher-shadow-lightblack: 0 5px 12px -5px rgba(102, 68, 68, 0.0);
    --zhsher-shadow-light2black: 0 5px 12px -5px rgba(102, 68, 68, 0.0);
    --zhsher-card-border: #3d3d3f;
    --zhsher-shadow-border: 0 8px 16px -4px #00000050;
    --style-border: 1px solid var(--zhsher-card-border);
    --style-border-hover: 1px solid var(--zhsher-theme);
    --style-border-hover-always: 1px solid var(--zhsher-theme);
    --style-border-dashed: 1px dashed var(--zhsher-theme-op);
    --style-border-forever: 2px solid var(--zhsher-lighttext);
}

/************ color.css end ************/

/************ cursor.css start ************/
/* 鼠标样式 */
/* 默认时 */
body {
    cursor: url(https://npm.elemecdn.com/zhsher-static/blog/css/close.ico),
    default;
}

/* 链接、图片 */
a,
img {
    cursor: url(https://npm.elemecdn.com/zhsher-static/blog/css/open.ico),
    default;
}

/************ cursor.css end ************/

/************ custom.css end ************/
/************魔改1 整体调整 ************/
/* 整体宽度 */
.page .layout, .post .layout {
    max-width: 1400px
}

/* 右侧边栏卡片 */
.aside-content {
    max-width: 312px;
    min-width: 250px
}

#aside-content .card-widget {
    border-radius: 12px;
    box-shadow: var(--zhsher-shadow-border);
    background: var(--zhsher-card-bg);
    border: var(--style-border);
    transition: .3s
}

/* 左侧首页、文章页布局 */
.layout > div:first-child {
    width: calc(100% - 300px)
}

@media screen and (max-width: 900px) {
    .aside-content {
        max-width: none !important
    }
}

@media screen and (max-width: 1200px) {
    .layout > div:first-child {
        width: calc(100% - 245px)
    }
}

/* 滑块样式 */
::-webkit-scrollbar-thumb {
    background-color: var(--zhsher-scrollbar);
    border-radius: 2em
}

/*::-webkit-scrollbar {*/
/*    width: 6px*/
/*}*/

/*body::-webkit-scrollbar, html::-webkit-scrollbar {*/
/*    width: 6px;*/
/*    height: 6px*/
/*}*/
/* 全局弹窗 */
.snackbar-container {
    background: var(--zhsher-main) !important;
    color: var(--zhsher-white) !important;
    border-radius: 0 !important;
    display: flex;
    justify-content: center !important;
    max-width: none !important;
    min-width: 100% !important;
    margin: 0;
    left: 0 !important;
    height: 60px !important;
    transform: none !important;
    margin: 0 !important
}

.snackbar-container p {
    font-weight: 700 !important;
    text-align: center !important;
    font-size: 1.1rem !important
}

.snackbar-container::after {
    position: absolute;
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--zhsher-white);
    opacity: .1;
    content: '';
    animation: snackbar-progress var(--zhsher-snackbar-time) linear forwards;
    pointer-events: none
}

@keyframes snackbar-progress {
    from {
        width: 0
    }
    to {
        width: 100%
    }
}

/* 首页文章卡片 */
#recent-posts .recent-post-item:hover {
    border: var(--style-border-hover);
    box-shadow: var(--zhsher-shadow-main)
}

/* 首页文章标题hover */
#recent-posts .recent-post-item:hover .recent-post-info .article-title {
    color: var(--zhsher-theme)
}

/* 设置圆角 排除前三个挂载插件 */
#recent-posts .recent-post-item:has(#github_container), #recent-posts .recent-post-item:has(#swiper_container), #recent-posts .recent-post-item:has(#catalog_magnet) {
    border-radius: 8px;
}

#recent-posts .recent-post-item {
    height: fit-content;
    background: var(--zhsher-card-bg);
    border-radius: 30px;
    /*cursor: url(/img/x2.cur), auto;*/
    border: var(--style-border)
}

/* 首页文章标题 */
.recent-post-item .recent-post-info .article-title {
    font-weight: 700
}

/*!* 文章页透明 *!*/
/*div#post {*/
/*    background: rgba(255, 255, 255, .9)*/
/*}*/

/*!* 首页文章透明 *!*/
/*#recent-posts > .recent-post-item:not(:first-child):active {*/
/*    transform: scale(.97)*/
/*}*/

/*!* categories页透明 *!*/
/*div#page {*/
/*    background: rgba(255, 255, 255, .9)*/
/*}*/

/*!* archives页透明 *!*/
/*div#archive {*/
/*    background: rgba(255, 255, 255, .9)*/
/*}*/

/*!* tag页透明 *!*/
/*div#tag {*/
/*    background: rgba(255, 255, 255, .9)*/
/*}*/

/*!* category页透明 *!*/
/*div#category {*/
/*    background: rgba(255, 255, 255, .9)*/
/*}*/

/* algo搜索 */
#algolia-search #search-mask {
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    background: var(--zhsher-maskbg)
}

#algolia-search .search-dialog {
    border-radius: 12px;
    box-shadow: var(--zhsher-shadow-lightblack);
    background: var(--zhsher-card-bg);
    border: var(--style-border);
    transition: .3s
}

#algolia-search .search-dialog .ais-Pagination .ais-Pagination-item--selected a {
    border-radius: 4px;
    background: var(--zhsher-main);
    color: var(--zhsher-white)
}

#algolia-search .search-dialog:hover {
    border: var(--style-border-hover);
    box-shadow: var(--zhsher-shadow-theme)
}

#algolia-search .category-lists ul li:before, #algolia-search .list-beauty li:before, #algolia-search .search-dialog .ais-Hits-list li:before {
    display: none
}

#algolia-search .category-lists ul li, #algolia-search .list-beauty li, #algolia-search .search-dialog .ais-Hits-list li {
    padding: 10px;
    border-radius: 8px;
    border: var(--style-border);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

#algolia-search .search-dialog .ais-Hits-list a {
    color: var(--search-result-title);
    display: block;
    font-weight: 600
}

#algolia-search .search-dialog .ais-Hits-list .search-result-tags a {
    padding: 4px 8px;
    border-radius: 8px;
    margin-right: .5rem;
    margin-top: .5rem;
    border: var(--style-border);
    display: inline
}

/************本地搜索**************/
/*#local-search .search-dialog .local-search__hit-item:before {*/
/*    content: none !important*/
/*}*/

/*#local-search .search-dialog .local-search__hit-item {*/
/*    padding: 10px !important;*/
/*    border-radius: 8px;*/
/*    border: var(--style-border);*/
/*    margin-bottom: 10px;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center*/
/*}*/

/*#local-search .search-dialog .local-search__hit-item .search-left {*/
/*    width: 35%*/
/*}*/

/*#local-search .search-dialog .local-search__hit-item .search-right {*/
/*    width: 60%*/
/*}*/

/*#local-search .search-dialog .local-search__hit-item .search-left img {*/
/*    object-fit: cover;*/
/*    width: 100%;*/
/*    background: var(--zhsher-secondbg);*/
/*    border-radius: 8px*/
/*}*/

/*#local-search .search-dialog .local-search__hit-item .search-result {*/
/*    cursor: pointer*/
/*}*/

/*#local-search .tag-list {*/
/*    padding: 4px 8px;*/
/*    border-radius: 8px;*/
/*    margin-right: .5rem;*/
/*    margin-top: .5rem;*/
/*    border: var(--style-border);*/
/*    cursor: pointer*/
/*}*/

/*#local-search .search-dialog .local-search__hit-item .tag-list {*/
/*    display: inline*/
/*}*/

/*#local-search .search-dialog .local-search__hit-item .tag-list:hover {*/
/*    background: var(--zhsher-main);*/
/*    color: var(--zhsher-white)*/
/*}*/

/* 移动端导航 */
#sidebar #sidebar-menus .menus_items .menus_item_child {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}

#sidebar #sidebar-menus .menus_item_child li {
    width: calc(50% - 8px);
    margin: 4px
}

#sidebar #sidebar-menus .menus_item_child .site-page.child {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    border-radius: 12px;
    border: var(--style-border-always);
    background: var(--zhsher-card-bg);
    font-size: 14px
}

#sidebar #sidebar-menus .menus_items a.site-page, #sidebar .menus_item ul {
    padding-left: 0
}

#sidebar #sidebar-menus.open {
    border-radius: 12px 0 0 0;
}

/* 翻页按钮 */
@media screen and (min-width: 1300px) {
    /* 上下页按钮 */
    #pagination a.extend.next:hover, #pagination a.extend.prev:hover {
        transform: scale(1.03)
    }
}

@media screen and (min-width: 768px) {
    /* 当前页 */
    #pagination .page-number.current:hover {
        background: var(--zhsher-theme);
        box-shadow: var(--zhsher-shadow-theme);
        color: var(--zhsher-white)
    }

    /* 上下页按钮 */
    #pagination a.extend.next:hover, #pagination a.extend.prev:hover {
        color: var(--zhsher-theme);
        border: var(--style-border-hover);
        transform: scale(1.03)
    }

    nav#pagination {
        overflow: visible
    }

    #pagination .page-number.current {
        background: var(--zhsher-theme);
        border: var(--style-border-hover);
        box-shadow: var(--zhsher-shadow-theme)
    }

    #pagination a.extend.next {
        overflow: hidden
    }

    #pagination a.extend.next, #pagination a.extend.prev {
        width: 5rem !important;
        line-height: 1.9rem !important;
        border-radius: 8px !important;
        background: var(--zhsher-card-bg);
        box-shadow: var(--zhsher-shadow-border);
        border: var(--style-border);
        display: flex !important;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transition: .3s;
        position: absolute
    }

    #pagination .page-number {
        background: var(--zhsher-card-bg);
        border-radius: 8px !important;
        margin: 0 .3rem;
        box-shadow: var(--zhsher-shadow-border);
        border: var(--style-border);
        transition: .3s
    }

    #pagination .page-number:hover {
        color: var(--zhsher-theme);
        border: var(--style-border-hover);
        box-shadow: var(--zhsher-shadow-main)
    }
}

/* 上 页数 下 按钮居中对齐 */
#pagination .pagination {
    position: relative;
    display: flex;
    justify-content: center
}

/* 上下页动画 */
a.extend.prev:before {
    position: absolute;
    content: '上一页';
    left: -55%;
    display: flex;
    transition: .3s;
}

a.extend.prev:hover:before {
    position: absolute;
    left: 25%;
}

a.extend.prev:hover .fa-chevron-left:before {
    opacity: 0;
}

a.extend.next:before {
    position: absolute;
    content: '下一页';
    left: -55%;
    display: flex;
    transition: .3s;
}

a.extend.next:hover:before {
    position: absolute;
    left: 25%;
}

a.extend.next:hover .fa-chevron-right:before {
    opacity: 0;
}

/*上一页按钮左对齐*/
a.extend.prev {
    left: 0
}

/*下一页按钮右对齐*/
a.extend.next {
    right: 0
}

#pagination {
    overflow: hidden;
    margin-top: 1rem;
    width: 100%
}

/************ 魔改2 文章内容调整 ************/
/* 2.1 卡片颜色 */
div#post {
    background: var(--zhsher-card-bg);
}

/* 2.2文章中的超链接（不包括图片、标题小节、友链朋友圈） */
#article-container a:not([data-fancybox]):not(.headerlink):not(.cf-article-title) {
    text-decoration: none;
    border-bottom: 2px solid var(--zhsher-lighttext);
    color: var(--zhsher-fontcolor);
    font-weight: bold;
    padding: 0 4px;
}

/* 不包括友链 */
#article-container .flink-list-item a {
    border-bottom: 0px !important;
    background: none !important;
    font-weight: 4!important;
    padding: 0px!important;
}

/* hover */
#article-container a:not([data-fancybox]):not(.headerlink):not(.cf-article-title):hover {
    text-decoration: none;
    color: var(--zhsher-white);
    background: var(--zhsher-main);
    border-radius: 4px;
    box-shadow: var(--zhsher-shadow-main);
}

/* 2.3代码块 */
#article-container code {
    color: var(--zhsher-white);
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    margin: 0px 4px;
    background: var(--zhsher-pink);
    line-height: 2;
    box-shadow: var(--zhsher-shadow-border);
}

/* 2.4无序、有序列表 */
#article-container ol, #article-container ul {
    margin-top: .4rem;
    padding: 0 0 0 .8rem;
    list-style: none;
    counter-reset: li 0; /*计数器*/
}

/* 去掉原本的默认样式 */
#article-container.post-content li::marker {
    content: none
}

#article-container ol li:not(.tab), #article-container ul li:not(.tab) {
    position: relative;
    margin: .2rem 0
}

#article-container ul > li:not(.tab) {
    padding: .2em .2em .2em 1.4em
}

#article-container ul > li:not(.tab):before {
    border: .21em solid var(--zhsher-lighttext);
    background: var(--zhsher-lighttext)
}

#article-container ul > li:not(.tab)::before {
    top: .78em;
    width: .42em;
    height: .42em;
    border-radius: .42em;
    content: "";
    line-height: .42em
}

#article-container ol li::before, #article-container ul li::before {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--zhsher-theme);
    color: #fff;
    transition: all .3s ease-out 0s
}

#article-container ol > li::before {
    margin-top: .65em;
    width: 1.45em;
    height: 1.45em;
    border-radius: .725em;
    content: counter(li);
    counter-increment: li 1;
    text-align: center;
    font-size: .85em;
    line-height: 1.45em
}

#article-container ol li:not(.tab), #article-container ul li:not(.tab) {
    position: relative;
    margin: .2rem 0
}

#article-container ol > li:not(.tab) {
    padding: .2em .2em .2em 1.8em
}

/* 2.5文章引用 */
blockquote {
    border: var(--style-border);
    background-color: var(--zhsher-secondbg);
    color: var(--zhsher-secondtext);
    border-radius: 8px;
    margin: 1rem 0;
    padding: 1rem 0.8rem;
}

/* 2.6文章内的表格 */
table thead {
    background: var(--zhsher-secondbg)
}

table td, table th {
    padding: .3rem .6rem;
    border: var(--style-border-always)
}

.table-wrap {
    margin: 1rem 0;
    border-radius: 8px;
    overflow-x: scroll
}

/* 2.7文章中的加粗文本颜色 */
b, strong {
    color: var(--zhsher-lighttext);
}

/* 2.8版权信息 */
.layout #post .post-copyright {
    border: var(--style-border-hover);
    border-radius: 15px;
    overflow: hidden
}

/* 放大原先的CC标识*/
/*.layout #post .post-copyright:before {*/
/*    top: -71px;*/
/*    right: -20px;*/
/*    font-size: 8.3em*/
/*}*/
.layout #post .post-copyright:before {
    display: none;
}

/* CC图标 */
#post .post-copyright:after {
    position: absolute;
    right: 22px;
    top: -90px;
    content: '\f25e';
    font-size: 180px;
    font-family: 'Font Awesome 5 Brands';
    color: var(--zhsher-fontcolor);
    opacity: 0.4;
    filter: blur(5px);
}

/* a标签调整 */
#post .post-copyright a:not([data-fancybox=gallery]) {
    font-weight: 500;
    border-bottom: solid 2px var(--zhsher-lighttext);
    color: var(--zhsher-fontcolor);
    padding: 0 .2em;
    text-decoration: none
}

#post .post-copyright a:not([data-fancybox=gallery]):hover {
    color: var(--zhsher-white);
    background-color: var(--zhsher-main);
    border-radius: 4px
}

/* 2.9文章小节标题 */
/* 去除主题配置默认的beautify矢量图 */
#article-container.post-content h1:before, #article-container.post-content h2:before, #article-container.post-content h3:before, #article-container.post-content h4:before, #article-container.post-content h5:before, #article-container.post-content h6:before {
    display: none
}

/* 锚点图 */
#article-container .headerlink::before {
    /*content: "\f0c1";*/
    content: "📌";
    font-family: "Font Awesome 6 Pro"
}

/* h1-h3锚点位置、透明处理 */
#article-container h1 .headerlink {
    float: right;
    opacity: .08;
    position: relative;
    padding: 0;
    border: none;
    line-height: 1;
    font-size: 2rem;
    border-radius: 8px !important;
    text-decoration: none;
    font-weight: 700
}

#article-container h2 .headerlink {
    float: right;
    opacity: .08;
    position: relative;
    padding: 0;
    border: none;
    line-height: 1;
    font-size: 1.8rem;
    border-radius: 8px !important;
    text-decoration: none;
    font-weight: 700
}

#article-container h3 .headerlink {
    float: right;
    opacity: .08;
    position: relative;
    padding: 0;
    border: none;
    line-height: 1;
    font-size: 1.3rem;
    border-radius: 8px !important;
    text-decoration: none;
    font-weight: 700
}

/* h1-h6标题效果 */
#article-container.post-content h1, #article-container.post-content h2, #article-container.post-content h3, #article-container.post-content h4, #article-container.post-content h5, #article-container.post-content h6 {
    font-size: 1.6rem;
    line-height: 1.6;
    border-top: 1px dashed var(--zhsher-theme-op);
    padding-top: 1.5rem;
    padding-left: 0
}

/* 2.10文章代码框 */
figure {
    margin-top: 0.5rem !important;
}

/* text部分 */
#article-container figure.highlight .highlight-tools {
    background: var(--zhsher-secondbg) !important;
    border-bottom: var(--style-border-always);
}

/* 行号 */
#article-container .gutter {
    opacity: 0.6;
}

/* 语言 */
#article-container .code-lang {
    margin-left: 6px;
}

#article-container .highlight-tools .code-lang {
    text-transform: capitalize;
    left: 1.9rem;
}

/* 滚动条 */
#article-container figure.highlight table::-webkit-scrollbar {
    color: var(--zhsher-blue);
    background: var(--zhsher-secondbg);
    height: 8px;
}

#article-container figure.highlight table::-webkit-scrollbar-thumb {
    background: var(--zhsher-gray);
}

#article-container figure.highlight table::-webkit-scrollbar-thumb:hover {
    background: var(--zhsher-main);
}

/* 代码展开图标 */
#article-container .code-expand-btn i {
    color: var(--zhsher-fontcolor);
    font-size: 1rem;
    padding: 0.3rem 0px;
    animation: 1.2s ease 0s infinite normal none running code-expand-key;
}

#article-container .code-expand-btn {
    background: var(--zhsher-secondbg);
    transition: 0.3s;
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backdrop-filter: blur(20px);
    transform: translateZ(0);
    border-top: var(--style-border-always);
    position: absolute;
    bottom: 0px;
    z-index: 10;
    width: 100%;
    text-align: center;
    font-size: var(--global-font-size);
    cursor: pointer;
}

#article-container .code-expand-btn:hover {
    background: var(--zhsher-main);
}

#article-container .code-expand-btn:hover i {
    color: var(--zhsher-white);
}

/* 2.11文章标签 */
.post-meta__tag-list a:nth-child(5n) {
    background-color: #4a4a4a;
    color: #fff
}

/* 多色处理 */
.post-meta__tag-list a:nth-child(5n+1) {
    background-color: #ff5e5c;
    color: #fff
}

.post-meta__tag-list a:nth-child(5n+2) {
    background-color: #ffbb50;
    color: #fff
}

.post-meta__tag-list a:nth-child(5n+3) {
    background-color: #1ac756;
    color: #fff
}

.post-meta__tag-list a:nth-child(5n+4) {
    background-color: #19b5fe;
    color: #fff
}

.post-meta__tag-list a:hover {
    background-color: #1b1b1b;
    color: #fff
}

/* 夜间透明 */
[data-theme=dark] .post-meta__tag-list a {
    opacity: .5
}

.post-meta__tag-list a {
    color: white !important;
    border: 1px solid var(--zhsher-card-border) !important;
    line-height: 30px;
}

/* 2.12tag-hide标签外挂 https://akilar.top/posts/615e2dec/ */
details.folding-tag[open] {
    border-radius: 15px !important;
    border-color: var(--zhsher-main) !important;
    border: 1px solid var(--zhsher-main) !important
}

li details.folding-tag {
    border-radius: 8px
}

li details.folding-tag summary {
    border-radius: 8px
}

details.folding-tag {
    background: 0 0 !important;
    transition: border 0s ease !important;
    border: none !important
}

details.folding-tag[open] > summary {
    color: var(--zhsher-white) !important;
    background: var(--zhsher-main) !important;
    border: 1px solid var(--zhsher-main) !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

details.folding-tag > summary {
    border: var(--style-border) !important;
    background: var(--zhsher-card-bg) !important;
    border-radius: 14px !important;
    box-shadow: var(--zhsher-shadow-border);
    color: var(--font-color) !important
}

details.folding-tag > summary::marker {
    color: var(--zhsher-main)
}

details.folding-tag[open] > div.content {
    border-radius: 0 0 16px 16px
}

details.folding-tag summary:hover::marker {
    color: var(--zhsher-white)
}

details.folding-tag[open] > summary::marker {
    color: var(--zhsher-white)
}

details.folding-tag summary:hover {
    cursor: pointer;
    background: var(--zhsher-main) !important;
    color: var(--zhsher-white) !important;
    transition: .3s;
    box-shadow: var(--zhsher-shadow-main)
}

details .content > :nth-child(1) {
    margin-top: 0 !important
}

/* 友链下的样式 */
.flink details.folding-tag[open] > div.content {
    padding: 20px;
    margin: 0;
    margin-top: 0
}

/* 2.13tab标签外挂 */
#article-container .tabs {
    border: 3px solid var(--zhsher-secondbg) !important
}

#article-container .tabs {
    margin: 1rem 0 !important;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: var(--zhsher-shadow-border)
}

#article-container .tabs > .nav-tabs > .tab button {
    border-bottom: 2px solid var(--tab-border-color);
    border-top: none !important
}

#article-container .tabs > .nav-tabs > .tab.active button {
    border-bottom: 2px solid #3b70fc;
    border-top: none !important
}

.tab-item-content {
    background: var(--zhsher-card-bg)
}

#article-container figure.highlight {
    border-radius: 10px
}

/* 2.14note+hide标签外挂 */
.note .hide-block {
    margin: 0
}

.note .hide-block button {
    border-radius: 30px
}

/* 2.15图片罩着去掉 */
[data-theme=dark] img {
    filter: brightness(1)
}

/* 2.16文章底部上下篇文章、相关文章推荐 */
.relatedPosts > .relatedPosts-list > div, #pagination.pagination-post {
    border-radius: var(--zhsher-border-radius)
}

/* 2.17文章页卡片圆角 */
.layout > div:first-child:not(.recent-posts) {
    border-radius: 12px
}

@media screen and (max-width: 768px) {
    .layout > div:first-child:not(.recent-posts) {
        border-radius: 12px 12px 0 0
    }
}

/* 2.18文字 */
#article-container {
    color: var(--zhsher-fontcolor);
}

#article-container p {
    text-align: left;
    letter-spacing: 0.6px;
    /* 字体样式 */
    font-size: 0.9rem;
    line-height: 1.7;
    font-weight: 400;
}


/* 2.19文字选中 */
::selection {
    background: var(--zhsher-main);
    color: var(--zhsher-background);
}

/* 文章内代码选中 */
#article-container code::selection {
    background: var(--zhsher-main) !important;
}

/************ custom.css end ************/

/************ dark span.css start ************/
/* 萌娘百科 “黑幕” */
/* 以下内容引用自萌娘百科 https://zh.moegirl.org.cn/MediaWiki:Gadget-site-styles.css，感谢！ */
/* MoeGirl Start */
/* 参考https://www.initbili.top/2022/dba42bb67415/ */
/* <span class="heimu" title="你知道的太多了">内容</span> */
.heimu,
.heimu rt {
    background-color: gray;
}

.heimu,
.heimu a,
a .heimu,
a.new .heimu,
span.heimu a.new,
span.heimu a.external,
span.heimu a.external:visited,
span.heimu a.extiw,
span.heimu a.extiw:visited,
span.heimu a.mw-disambig,
span.heimu a.mw-redirect {
    transition: color 0.13s linear;
    color: gray;
    text-shadow: none;
}

span.heimu:hover,
span.heimu:active {
    color: white;
}

span.heimu:hover a,
a:hover span.heimu {
    color: lightblue;
}

span.heimu:hover a:visited,
a:visited:hover span.heimu {
    color: #C5CAE9;
}

span.heimu:hover a.new,
a.new:hover span.heimu {
    color: #FCC;
}

span.heimu a.new:hover:visited,
a.new:hover:visited span.heimu {
    color: #EF9A9A;
}

span.heimu:hover a.extiw:visited,
a.extiw:visited:hover span.heimu {
    color: #D1C4E9;
}

/************ dark span.css end ************/

/************ double row.css start ************/
#recent-posts {
    margin-top: -1rem; /*头部抵消间隔*/
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap; /*规定灵活的项目在必要的时候拆行或拆列。*/
    justify-content: space-between; /*。*/
}

#recent-posts > .recent-post-item {
    /*max-height:324px;*/ /*文章容器最大高度*/
    margin-top: 1rem; /*最小间距*/
    display: inline-block;
    height: auto; /*高度自动*/
    width: 49%; /*文章容器容器宽度*/
}

#recent-posts > .recent-post-item .post_cover {
    width: 100%; /*图片封面宽度*/
    height: 225px; /*图片封面高度*/
}

#recent-posts > .recent-post-item .post_cover img.post_bg {
    width: 100%; /*图片宽度*/
    height: 100%; /*图片高度*/
}


#recent-posts > .recent-post-item > .recent-post-info > .content {
    display: none; /*隐藏文章详情*/
}

#recent-posts > .recent-post-item {

    -webkit-flex-direction: column; /*容器内部纵向排列*/
    -ms-flex-direction: column; /*容器内部纵向排列*/
    flex-direction: column; /*容器内部纵向排列*/

}

#recent-posts > .recent-post-item .left_radius {
    border-radius: 8px 8px 0 0; /*圆角修改*/
}

#recent-posts > .recent-post-item .right_radius {
    border-radius: 8px 8px 0 0; /*圆角修改*/
}

.recent-post-item {
    height: auto !important; /*容器高度自动*/
}

.recent-post-info {

    padding: 0 40px; /*容器内部文字左右间距*/
    margin-top: 1em; /*容器内部文字上间距*/
    width: 100% !important; /*容器宽度*/
}

#recent-posts > .recent-post-item > .recent-post-info > .article-title {
    -webkit-line-clamp: 1; /*控制标题的行数*/
    margin-top: 0.3rem; /*控制标题的上间距*/
    margin-bottom: 0.3rem; /*控制标题的下间距*/
    color: var(--text-highlight-color);
    font-size: 1.2em; /*控制标题的字体大小*/
    line-height: 1.4; /*控制标题的行高*/

}

#recent-posts > .recent-post-item > .recent-post-info > .article-meta-wrap {
    margin-bottom: 1rem; /*控制标题meta信息的底部间距*/
}

@media screen and (max-width: 768px) {
    #recent-posts > .recent-post-item {
        width: 100%; /*控制手机自适应*/
    }
}

/* 冰老师微调 */
nav#pagination {
    width: 100%;
}

/************ double row.css end ************/

/************ memos.css start ************/
/* maintop */

div#main_top {
    z-index: 1;
    max-width: 1400px;
    margin: 20px auto -15px;
    width: 100%;
    padding: 0 15px;
}

@media screen and (min-width: 2000px) {
    div#main_top {
        max-width: 1500px;
    }
}

#bber-talk {
    border-radius: 12px;
    box-shadow: none;
    border: 1px solid #e0e3ed;
    box-sizing: border-box;
    transition: all .3s ease-in-out;
    cursor: pointer;
    width: 100%;
    min-height: 50px;
    background: var(--zhsher-card-bg);
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    font-weight: 700;
}

#bber-talk:hover {
    border-color: #49b1f5;
    box-shadow: none;
}

#bber-talk,
#bber-talk a {
    color: var(--font-color);
}

#bber-talk svg.icon {
    width: 1em;
    height: 1em;
    vertical-align: -.15em;
    fill: currentColor;
    overflow: hidden;
    font-size: 20px;
}

#bber-talk .item i {
    margin-left: 5px;
}

#bber-talk > i {
    font-size: 1.1rem;
}

#bber-talk .talk-list {
    flex: 1;
    max-height: 32px;
    font-size: 16px;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

#bber-talk .talk-list :hover {
    color: #49b1f5 !important;
    transition: all .2s ease-in-out;
}

#bber-talk .talk-list li {
    list-style: none;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 10px;
}

@media screen and (min-width: 768px) {
    #bber-talk .talk-list {
        text-align: center;
        margin-right: 20px;
    }
}

/************ memos.css end ************/

/************ nav_menu.css start ************/
#nav a:hover {
    background: var(--zhsher-theme);
    transition: 0.3s;
}

#nav-totop:hover .totopbtn i {
    opacity: 1;
}

#nav-totop #percent {
    font-size: 12px;
    background: var(--zhsher-white);
    color: var(--zhsher-theme);
    width: 25px;
    height: 25px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.nav-fixed #nav-totop #percent,
.page #nav-totop #percent {
    background: var(--font-color);
    color: var(--card-bg);
    font-size: 13px;
}

#nav-totop {
    width: 35px;
}

#page-header:not(.is-top-bar) #percent {
    transition: 0.3s;
}

#page-header:not(.is-top-bar) #nav-totop {
    width: 0;
    opacity: 0;
    transition: width 0.3s, opacity 0.2s;
    margin-left: 0 !important;
}

#nav-totop #percent {
    font-weight: 700;
}

#nav-totop:hover #percent {
    opacity: 0;
    transform: scale(1.5);
    font-weight: 700;
}

#page-header #nav #nav-right div {
    margin-left: 0.5rem;
    padding: 0;
}

#nav-totop {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.nav-button {
    cursor: pointer;
}

div#menus {
    display: flex;
    align-items: center;
}

#page-header #nav .nav-button a {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#nav .site-page {
    padding-bottom: 0px;
}

#nav *::after {
    background-color: transparent !important;
}

/* 顶栏修改 */
#nav .menus_items .menus_item .menus_item_child li a {
    padding: 2px 16px;
}

#nav .menus_items .menus_item .menus_item_child li:hover a {
    color: white !important;
}

#nav .menus_items .menus_item .menus_item_child li {
    margin: 6px;
    border-radius: 5px;
    transition: all 0.3s;
    display: inline-block;
    margin: 0 3px;
}

#nav .menus_items .menus_item .menus_item_child:before {
    top: -19px;
}

#site-name,
.shuoshuo {
    white-space: nowrap;
    overflow: hidden;
}

#site-name {
    padding: 0 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

#blog-info #site-name i {
    opacity: 0;
    position: absolute;
}

#blog-info #site-name:hover .title {
    opacity: 0;
}

#blog-info #site-name:hover i {
    opacity: 1;
    transform: scale(1.01);
    color: white;
}

/* 圆角隐藏 */
ul.menus_item_child {
    border-radius: 5px;
}

/* 一级菜单居中 */
#nav .menus_items {
    position: absolute;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60px;
}

#nav .menus_items .menus_item:hover .menus_item_child {
    display: block;
    transform: translateX(-50%);
    right: auto;
    left: auto !important;
    padding: 6px 4px;
    box-sizing: content-box;
    line-height: 35px;
}

#nav .menus_items .menus_item:hover {
    padding: 0 5px 27px 5px !important;
    margin-bottom: -14.5px !important;
}

#nav .menus_items .menus_item .menus_item_child {
    top: 44px;
}

@media screen and (min-width: 768px) {
    .page .menus_item:hover > a.site-page {
        color: var(--zhsher-white) !important;
        background: var(--zhsher-theme);
        transition: 0.3s;
        box-shadow: var(--zhsher-shadow-main);
    }
}

.nav-fixed #nav {
    transform: translateY(58px) !important;
}

#nav {
    padding: 0 calc((100% - 1420px) / 2);
    backdrop-filter: saturate(180%) blur(20px);
}

#nav a {
    border-radius: 8px;
    color: var(--font-color);
}

.page #nav a:hover {
    color: var(--zhsher-white) !important;
    background: var(--zhsher-theme);
    transition: 0.3s;
    box-shadow: var(--zhsher-shadow-main);
}

#menus > div.menus_items > div > a {
    letter-spacing: 0.3rem;
    font-weight: 700;
    padding: 0em 0.3em 0em 0.5em;
    height: 35px;
    line-height: 35px;
}

#nav .menus_items .menus_item {
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

#nav div#toggle-menu {
    padding: 2px 0 4px 6px;
}

#nav-totop .totopbtn i {
    position: absolute;
    display: flex;
    opacity: 0;
}

#page-name::before {
    font-size: 18px;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    color: white !important;
    top: 0;
    left: 0;
    content: "回到顶部";
    background-color: var(--zhsher-theme);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    opacity: 0;
    box-shadow: 0 0 3px var(--zhsher-theme);
    line-height: 45px; /*如果垂直位置不居中可以微调此值，也可以删了*/
}

#page-name:hover:before {
    opacity: 1;
}

#name-container {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}

#name-container:hover {
    transform: translateX(-50%) scale(1.03);
}

#page-name {
    position: relative;
    padding: 10px 30px;
}

center#name-container {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-family: "ZhuZiAYuanJWD";
}

.nav-fixed.nav-visible #name-container {
    transition: 0.3s;
    transform: translate(-50%, 60px);
}

.nav-fixed.nav-visible #menus .menus_items {
    transform: translate(-50%);
    transition: 0.3s;
    line-height: 60px;
}

.nav-fixed #menus .menus_items {
    transform: translate(-50%, -60px);
    transition: 0.3s;
}

.nav-fixed #name-container {
    top: 15%;
    transition: 0.3s;
}

#name-container {
    bottom: 60px;
}

.mask-name-container {
    max-width: 1200px;
    width: 50%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (max-width: 992px) {
    .mask-name-container {
        width: 65%;
    }
}

@media screen and (max-width: 768px) {
    .mask-name-container {
        display: none;
    }
}

#sidebar #sidebar-menus .menus_items .site-page:hover {
    color: var(--zhsher-white);
    border-radius: var(--zhsher-border-radius);
}

#nav .menus_items .menus_item > a > i:last-child {
    display: none;
}

#nav #search-button {
    font-size: 1.3em;
}

@media screen and (min-width: 900px) {
    #nav .back-home-button:hover {
        box-shadow: var(--zhsher-shadow-main);
    }
}

.back-home-button:hover {
    background: var(--zhsher-theme);
    color: var(--zhsher-white) !important;
}

.back-home-button {
    display: flex;
    width: 35px;
    height: 35px;
    padding: 0 !important;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    transition: 0.3s;
    border-radius: 8px;
}

.back-home-button:hover .back-menu-list-groups {
    display: flex;
    opacity: 1;
    transition: 0.3s;
    top: 55px;
    pointer-events: auto;
    left: 0;
}

.back-home-button .back-menu-list-groups {
    position: absolute;
    top: 65px;
    left: 0;
    background: var(--zhsher-card-bg);
    border-radius: 12px;
    border: var(--style-border);
    flex-direction: column;
    font-size: 12px;
    color: var(--zhsher-secondtext);
    box-shadow: var(--zhsher-shadow-border);
    transition: 0s;
    opacity: 0;
    pointer-events: none;
}

.back-home-button .back-menu-list-group {
    display: flex;
    flex-direction: column;
}

.back-home-button .back-menu-list-group .back-menu-list-title {
    margin: 8px 0 0 16px;
    transition: 0.3s;
}

.back-home-button .back-menu-list {
    display: flex;
    /* 微调 */
    flex-direction: row;
    width: 332px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.back-home-button .back-menu-list::before {
    position: absolute;
    top: -22px;
    left: 0px;
    width: 100%;
    height: 25px;
    content: "";
}

.back-home-button .back-menu-list-group:hover .back-menu-list-title {
    color: var(--zhsher-theme);
}

.back-home-button .back-menu-list-groups:hover {
    border: var(--style-border-hover);
}

.back-home-button .back-menu-list .back-menu-item {
    display: flex;
    align-items: center;
    margin: 4px 8px;
    padding: 4px 8px !important;
    transition: 0.3s;
    border-radius: 8px;
    width: 150px; /* 调整 */
}

.back-home-button .back-menu-list .back-menu-item .back-menu-item-text {
    font-size: var(--global-font-size);
    margin-left: 0.5rem;
    color: var(--zhsher-fontcolor);
    white-space: nowrap;
}

#nav #blog-info {
    flex-wrap: nowrap;
    height: 60px;
    display: flex;
    align-items: center;
    /* z-index: 102; */
    transition: 0.3s;
}

.back-home-button .back-menu-list .back-menu-item .back-menu-item-icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: var(--zhsher-secondbg);
}

#page-header #nav .back-home-button {
    cursor: pointer;
    position: relative;
}

@media screen and (min-width: 1300px) {
    #nav a:hover {
        transform: scale(1.03);
    }
}

.back-home-button .back-menu-list .back-menu-item:hover .back-menu-item-text {
    color: var(--zhsher-white);
}

.back-menu-item-icon.loading img {
    width: 25px;
}

#page-header #nav #menus .nav-button.long a.totopbtn,
#page-header #nav #menus .nav-button.long,
#page-header #nav #menus .nav-button.long a.totopbtn span {
    width: 70px;
}

#page-header #nav #menus .nav-button.long a.totopbtn span {
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    white-space: nowrap;
}

#page-header #nav #menus .nav-button.long a.totopbtn:hover {
    border-radius: 35px;
    height: 30px;
}

#nav #search-button {
    padding-left: 0;
}

#page-header #nav .nav-button {
    margin-left: 0.5rem;
    padding: 0;
}

#page-header:not(.is-top-bar) #nav-totop a {
    display: none;
}

#search-button a.site-page.social-icon.search span {
    display: none;
}

/* 微调 */
#nav #blog-info {
    overflow: unset;
}

/************ nav_menu.css end ************/

/************ rightmenu.css start ************/
/* rightMenu */
[data-theme='light'] #rightMenu {
    display: none;
    position: fixed;
    width: 160px;
    height: fit-content;
    top: 10%;
    left: 10%;
    background-color: var(--card-bg);
    border: 1px solid rgb(210, 210, 210);;
    border-radius: 8px;
    z-index: 100;
    box-shadow: 3px 3px 5px #88888894;
    background-color: var(--zhshier-white-acrylic1);
    backdrop-filter: blur(30px);
}

[data-theme='dark'] #rightMenu {
    display: none;
    position: fixed;
    width: 160px;
    height: fit-content;
    top: 10%;
    left: 10%;
    background-color: var(--card-bg);
    border: 1px solid rgb(210, 210, 210);;
    border-radius: 8px;
    z-index: 100;
    box-shadow: 3px 3px 5px #88888894;
    background-color: var(--zhshier-black-acrylic1);
    backdrop-filter: blur(30px);
}

#rightMenu .rightMenu-group {
    padding: 7px 6px;
}

#rightMenu .rightMenu-group:not(:nth-last-child(1)) {
    border-bottom: 1px solid rgb(180, 180, 180);
}

#rightMenu .rightMenu-group.rightMenu-small {
    display: flex;
    justify-content: space-between;
}

#rightMenu .rightMenu-group .rightMenu-item {
    height: 30px;
    line-height: 30px;
    border-radius: 8px;
    transition: 0.3s;
    color: var(--font-color);
}

#rightMenu .rightMenu-group.rightMenu-line .rightMenu-item {
    display: flex;
    height: 40px;
    line-height: 40px;
    padding: 0 4px;
}

#rightMenu .rightMenu-group .rightMenu-item:hover {
    background-color: var(--text-bg-hover);
    box-shadow: 0px 0px 5px var(--zhshier-border);
}

#rightMenu .rightMenu-group .rightMenu-item i {
    display: inline-block;
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
    padding: 0 5px;
}

#rightMenu .rightMenu-group .rightMenu-item span {
    line-height: 30px;
}

#rightMenu:hover {
    border: 1px solid var(--zhshier-blue);
    /* box-shadow:0 0 3px var(--zhshier-blue)!important; */
}

#rightMenu .rightMenu-group.rightMenu-line .rightMenu-item * {
    height: 40px;
    line-height: 40px;
}

.rightMenu-group.hide {
    display: none;
}

.rightMenu-item:hover {
    color: white !important;
    background-color: var(--zhshier-blue) !important;
}

:root {
    --zhshier-border: #c9c9c9;
    --zhshier-blue: #6cf;
    --zhshier-black-acrylic1: #0008;
    --zhshier-white-acrylic1: #fffd;
}

/************ rightmenu.css end ************/

/************ social_card.css start ************/
div#author-info__sayhi {
    text-align: left;
    background: var(--zhsher-white-op);
    color: var(--font-color);
    border: var(--style-border);
    font-size: 12px;
    margin-right: auto;
    padding: 5px 8px;
    border-radius: 12px;
    width: fit-content;
    display: inline;
    margin-left: -1px
}

.card-widget .author-info-top {
    margin: 15px auto 0;
    display: flex;
    justify-content: center
}

.card-widget .card-info-avatar {
    display: inline-block;
    position: relative
}

.card-widget.card-info .banner-button-group {
    margin: 5px 0;
    display: flex
}

#aside-content .card-info .banner-button {
    height: 40px;
    width: 100%;
    border-radius: 20px;
    justify-content: center
}

.card-widget.card-info .banner-button-group .banner-button {
    padding: 20px 12px;
    background: var(--zhsher-theme);
    border-radius: 12px;
    color: var(--zhsher-white);
    display: flex;
    align-items: center;
    z-index: 1;
    transition: all .3s ease 0s;
    cursor: pointer
}

.card-widget.card-info .banner-button-group .banner-button .fas.fa-circle-arrow-up-right {
    font-size: 1.3rem;
    margin-right: 10px
}

.card-widget.card-info .banner-button-group .banner-button:hover {
    background: var(--zhsher-main-op-deep);
    color: var(--zhsher-white)
}

.card-info-avatar .avatar-img {
    width: 100px;
    height: 100px;
    display: block
}

.card-info-avatar .author-status-box {
    position: absolute;
    bottom: 0;
    left: calc(100% - 28px);
    width: 28px;
    height: 28px;
    border: var(--style-border);
    border-radius: 2em;
    background-color: var(--zhsher-card-bg);
    transition: .3s;
    overflow: hidden
}

.card-info-avatar .author-status-box .author-status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    padding: 0 5px
}

.card-info-avatar .author-status-box:hover {
    width: 105px
}

.card-info-avatar .author-status-box:hover .author-status span {
    width: 105px;
    margin-left: 4px
}

.card-info-avatar .author-status-box .author-status span {
    width: 0;
    font-size: 12px;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: .3s
}

#aside-content > .card-info {
    background: #fff url(https://zhsher.cn/Hexo_img/config/social_card.webp) top -24% center no-repeat;
    position: relative
}

[data-theme=dark] #aside-content > .card-info {
    background: var(--zhsher-card-bg)
}

@media screen and (max-width: 992px) {
    #aside-content > .card-info {
        background-size: 100% 70%
    }
}

.card-widget .author-info__name {
    line-height: 1.5em;
    margin: 4px 0
}

/************ social_card.css end ************/

/************ twikoo.css start ************/
.tk-expand {
    width: 100%;
    cursor: pointer;
    padding: .75em;
    text-align: center;
    transition: all .5s
}

#twikoo .tk-content p {
    margin: 0
}

#twikoo .tk-expand:hover {
    color: #fff;
    background-color: #3b70fc;
    border: 1px solid transparent;
}

.tk-expand {
    border: var(--style-border);
    box-shadow: 0 8px 16px -4px #2c2d300c;
    border-radius: 50px;
    letter-spacing: 5px;
    background-color: var(--zhsher-card-bg)
}

.tk-replies > .tk-comment {
    border-top: var(--style-border-dashed);
    border-radius: 12px;
    padding: 1rem 0 0;
    transition: all .3s ease 0s
}

.tk-replies .tk-content span:first-child:not(.token) {
    font-size: .5rem;
    color: var(--zhsher-secondtext)
}

#twikoo pre code {
    padding: none;
    background: 0 0;
    color: none;
}

#twikoo code {
    padding: 2px 4px;
    background: var(--zhsher-secondbg);
    color: #f47466
}

.el-input.el-input--small.el-input-group.el-input-group--prepend:nth-child(1):before {
    content: '输入QQ号会自动获取昵称和头像';
}

.el-input.el-input--small.el-input-group.el-input-group--prepend:nth-child(2):before {
    content: '收到回复将会发送到你的邮箱';
}

.el-input.el-input--small.el-input-group.el-input-group--prepend:nth-child(3):before {
    content: '可以通过昵称访问你的网站';
}

.el-input.el-input--small.el-input-group.el-input-group--prepend:focus-within::after, .el-input.el-input--small.el-input-group.el-input-group--prepend:focus-within::before {
    display: block
}

.el-input.el-input--small.el-input-group.el-input-group--prepend::before {
    display: none;
    position: absolute;
    top: -60px;
    white-space: nowrap;
    border-radius: 10px;
    left: 50%;
    transform: translate(-50%);
    padding: 14px 18px;
    background: #444;
    color: #fff
}

.el-input.el-input--small.el-input-group.el-input-group--prepend::after {
    display: none;
    content: "";
    position: absolute;
    border: 12px solid transparent;
    border-top-color: #444;
    left: 50%;
    transform: translate(-50%, -48px)
}

.vwrap {
    box-shadow: 2px 2px 5px #bbb;
    background: rgba(255, 255, 255, .3);
    border-radius: 8px;
    padding: 30px;
    margin: 30px 0 30px 0
}

.vcard {
    box-shadow: 2px 2px 5px #bbb;
    background: rgba(255, 255, 255, .3);
    border-radius: 8px;
    padding: 30px;
    margin: 30px 0 0 0
}

#twikoo .tk-extra {
    background: var(--zhsher-card-bg);
    border: var(--style-border-always);
    padding: 4px 8px;
    border-radius: 8px;
    margin-right: 4px;
    color: var(--zhsher-secondtext);
    margin-top: 6px;
    font-size: .8rem
}

#twikoo .tk-extra-text {
    font-size: .5rem
}

#twikoo .tk-replies .tk-content {
    font-size: .9rem
}

#twikoo .tk-content {
    margin-top: 0
}

.tk-content span a:not([data-fancybox=gallery]) {
    font-weight: 500;
    border-bottom: solid 2px var(--zhsher-lighttext);
    color: var(--zhsher-fontcolor);
    padding: 0 .2em;
    text-decoration: none
}

.tk-content span a:not([data-fancybox=gallery]):hover {
    color: var(--zhsher-white);
    background-color: var(--zhsher-theme);
    border-radius: 4px
}

.tk-main .tk-content span > a {
    border-bottom: none
}

#post-comment .comment-head {
    font-size: .8em !important;
    margin-bottom: .5rem
}

.el-input.el-input--small.el-input-group.el-input-group--prepend:focus-within::before {
    display: block;
    animation: commonTipsIn .3s
}

@keyframes commonTipsIn {
    0% {
        top: -50px;
        opacity: 0
    }
    100% {
        top: -60px;
        opacity: 1
    }
}

#twikoo .tk-input .el-textarea__inner {
    min-height: 130px !important;
    border-radius: 15px;
    display: block;
    resize: vertical;
    padding: 16px 16px 40px 16px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    font-size: inherit;
    color: #606266;
    background-color: var(--zhsher-card-bg);
    border: var(--style-border-always);
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1)
}

#page .el-textarea__inner {
    background: var(--zhsher-card-bg) !important;
    box-shadow: var(--zhsher-shadow-border);
    color: var(--zhsher-fontcolor) !important
}

#twikoo.twikoo .el-input__inner:focus, #twikoo.twikoo .el-textarea__inner:focus {
    border-color: var(--zhsher-main)
}

.tk-comments-container > .tk-comment {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
    transition: .3s;
    border-radius: 12px;
    padding: 0;
    padding-top: 1rem;
    border: none;
    border-top: var(--style-border-dashed)
}

#post-comment .comment-tips {
    background-color: rgba(103, 194, 58, .13);
    border: var(--style-border-always);
    border-color: var(--zhsher-green);
    color: var(--zhsher-green);
    border-radius: 8px;
    padding: 8px 12px;
    margin-top: .5rem;
    display: none
}

#post-comment .comment-tips.show {
    display: flex
}

#page .tk-comments-container > .tk-comment {
    background: var(--zhsher-card-bg);
    padding: 1rem;
    padding-bottom: 1rem;
    border: var(--style-border);
    border-top: var(--style-border);
    box-shadow: var(--zhsher-shadow-border)
}

.tk-content {
    margin-top: .5rem;
    overflow: auto;
    max-height: 500px
}

.page .el-input__inner {
    background: var(--zhsher-card-bg) !important
}

.tk-comments .tk-row-actions-start {
    position: relative;
    top: -56px;
    left: 17px
}

.tk-comments .tk-main .tk-row-actions-start {
    position: relative;
    top: -56px;
    left: 2px
}

.OwO .OwO-body {
    border: var(--style-border-always) !important;
    border-radius: 8px !important;
    overflow: hidden;
    background-color: var(--zhsher-maskbg) !important;
    backdrop-filter: saturate(180%) blur(10px);
    cursor: auto;
    top: 2.1em !important
}

@media screen and (max-width: 768px) {
    .OwO .OwO-body {
        width: 260px
    }

    .tk-comments .tk-main .tk-row-actions-start {
        left: 30px
    }
}

.OwO .OwO-body .OwO-items .OwO-item:hover {
    box-shadow: var(--zhsher-shadow-lightblack) !important;
    border-radius: 8px
}

#post-comment .tk-avatar.tk-clickable {
    margin: 5px
}

/* gif动图 */
.tk-input textarea {
    background-image: url('https://zhsher.cn/Hexo_img/config/Twikooback.gif'); /* 图片地址 */
    min-height: 120px !important; /* 默认是75px 拉高评论框大小 */
    background-size: 10em 10em; /* 设置图片长宽 */
    /* background-color: #ffffff; */
    transition: all 0.25s ease-in-out 0s; /* 动画过渡 */
}

.tk-input textarea:focus {
    background-position-y: 130px;
    transition: all .25s ease-in-out 0s !important;
}

/************ twikoo.css end ************/

/************ universe.css start ************/
#universe {
    display: block;
    position: fixed;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
}

@media screen and (max-width: 768px) {
    #universe {
        display: none;
    }

    /* 下面这个是切换手机背景的代码，有需要的可以使用，没需要的删了就行 */
    /*#web_bg {*/
    /*    background-image: url("https://img.vm.laomishuo.com/image/2021/05/2021053107390019.jpeg");*/
    /*}*/
}

/* leonus微调 */
[data-theme='light'] #universe{
    display: none;
}
/************ universe.css end ************/
